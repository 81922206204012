
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PAYMENT_TERM_OPTIONS } from '@/models/paymentTerms'
import {
  isNotEmpty,
  isRequired,
} from '@/utils/validators'
import { DateTime } from 'luxon'
import { snakeToTitleCase } from '@/utils/string'
import { getInvoiceSentDateSidebarDetails, submitInvoiceSentDateSidebarDetails } from '@/services/reservations'
import { EventBus } from '@/utils/event-bus'
import uniqBy from 'lodash/uniqBy'
import { PaymentTypeKey } from '@/utils/enum'

const DEFAULT_PAYMENT_TERM_DAYS = 30

@Component
export default class PurchaseOrderSidebar extends Vue {
  @Prop({ required: true}) readonly reservationExternalIds!: Array<string>

  paymentTerms: number | null = null
  invoiceSentDate: string | null = null
  dueDate: string | null = null
  invoiceNumber: string | null = null

  paymentTermOptions: Array<{ label: string; value: number }> = PAYMENT_TERM_OPTIONS
  loading: boolean = false

  notEmptyValidator(message: string) {
    return [isRequired(true, isNotEmpty, { req: message, error: message })]
  }

  async mounted() {
    const response = await Promise.all(this.reservationExternalIds.map((id: string) => getInvoiceSentDateSidebarDetails(id)))
    const sidebarDetails = response.map(r => r.data)

    const uniquePaymentTerms: Array<number | null> = uniqBy(sidebarDetails.map(sd => sd.paymentTermsDays))
    if (uniquePaymentTerms.length > 1 || uniquePaymentTerms.includes(null)) {
      await this.$store.dispatch('app/closeSidebarDialog')
      await this.$store.dispatch(
        'app/showAlert',
        { message: 'Reservations must have the same payment terms', type: 'error' },
        { root: true }
      )
      return
    }

    const uniquePaymentTypes = uniqBy(sidebarDetails.map(sd => sd.paymentTypeKey))
    if (uniquePaymentTypes.length > 1  || !uniquePaymentTypes.includes(PaymentTypeKey.BillAfterServices)) {
      await this.$store.dispatch('app/closeSidebarDialog')
      await this.$store.dispatch(
        'app/showAlert',
        { message: 'Reservations must all have payment type of Bill After Services', type: 'error' },
        { root: true }
      )
      return
    }

    const earliestSentDate = DateTime.min(...sidebarDetails.map(sd => DateTime.fromISO(sd.sentDate)))
    // If sent date is not set, default to today
    this.invoiceSentDate = earliestSentDate.toISODate() || DateTime.local().toISODate();

    // Map payment terms key on the reservation to the correct value in days
    const paymentTermsDays =  uniquePaymentTerms[0]
    this.paymentTerms = PAYMENT_TERM_OPTIONS.find(term => term.value === paymentTermsDays)?.value || DEFAULT_PAYMENT_TERM_DAYS

    this.recomputeDueDate()

    const uniqueInvoiceNumbers = uniqBy(sidebarDetails.map(sd => sd.invoiceNumber)).filter(invoice => invoice !== null)

    // Invoice number is not required, so it does not have a default
    if (uniqueInvoiceNumbers.length === 1) {
      this.invoiceNumber = uniqueInvoiceNumbers[0]
    }
  }

  @Watch('paymentTerms')
  onPaymentTermsChanged(newTerms: number | null) {
    this.recomputeDueDate()
  }

  @Watch('invoiceSentDate')
  onInvoiceSentDateChanged(newDate: string | null) {
    this.recomputeDueDate()
  }

  recomputeDueDate() {
    if (this.invoiceSentDate && this.paymentTerms) {
      const sentDate = DateTime.fromISO(this.invoiceSentDate)
      this.dueDate = sentDate.plus({ days: this.paymentTerms }).toISODate()
    }
  }

  async save() {
    this.loading = true

    try {
      const formattedInvoiceSentDate = DateTime.fromISO(this.invoiceSentDate).toISO()
      const formattedDueDate = DateTime.fromISO(this.dueDate).toISO()

      await Promise.all(
        this.reservationExternalIds.map(id => submitInvoiceSentDateSidebarDetails(
          id,
          formattedInvoiceSentDate,
          formattedDueDate,
          this.invoiceNumber,
        ))
      )

      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-query-request')
      await this.$store.dispatch('app/closeSidebarDialog')

    } catch (error) {

      console.error("Failed to update invoice sent date:", error)
      await this.$store.dispatch(
        'app/showAlert',
        { message: 'Failed to update invoice sent date.', type: 'error' },
        { root: true }
      )
    }

    this.loading = false
  }

}
